import React from 'react';
import Navbar from '../Navbar/Navbar';
import { Link } from 'react-router-dom';

const Successfuly = () => {
  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="successPay">
          <h3 className="successPay-heading">Đặt hàng thành công</h3>
          <p className="successPay-text">Vui lòng kiểm tra email!</p>
          <Link to="/">
            <span className="infoUser-button">Trang Chủ</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Successfuly;
