import React from 'react';
import Navbar from '../Navbar/Navbar';
import './InforUser.css';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { thoat, capnhatUserInfo } from '../../../authSlice';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import cloudinaryUpload from '../../../user/service/uploads';
const schema = yup.object({
  name: yup
    .string()
    .trim()
    .required('Không được bỏ trống')
    .min(2, 'Tên sản phẩm có tối thiểu 2 ký tự')
    .max(20, 'Tên  sản phẩm có tối đa 20 ký tự'),
  email: yup
    .string()
    .email('email có định dạng không hợp lệ')
    .trim()
    .required('Không được bỏ trống'),
  phone: yup
    .string()
    .matches(/^[0-9]{10}$/, 'Số điện thoại phải có đúng 10 chữ số')
    .required('Không được bỏ trống'),
  address: yup
    .string()
    .required('Không được bỏ trống')
    .test('has-comma', 'Địa chỉ phải được ngăn cách bởi dấu phẩy', (value) => {
      return value.includes(',');
    }),
  image: yup.string(),
  avatar: yup.mixed().test('size', 'Kích thước file quá lớn', (value) => {
    if (!value) return true; // Trường hợp không có file được chọn
    return value.size <= 5242880; // Kích thước file không vượt quá 5MB (5242880 bytes)
  }),
});

const InfoUser = () => {
  const navigate = useNavigate();
  const result = JSON.parse(localStorage.getItem('result'));
  var user = result?.userInfo;
  const dispatch = useDispatch();
  const idUser = user ? user.id_user : null;
  const form = useForm({
    defaultValues: {
      name: user?.name,
      phone: user?.phone,
      email: user?.email,
      image: user?.avatar,
      address: user?.address,
    },
    resolver: yupResolver(schema),
  });
  const { register, handleSubmit, formState, control } = form;
  const { errors, isSubmitSuccessful } = formState;
  const [uploading, setUploading] = useState(false);

  const handleFileUpload = (e) => {
    setUploading(true);
    const uploadData = new FormData();
    uploadData.append('file', e.target.files[0], 'file');
    cloudinaryUpload(uploadData)
      .then((res) => {
        form.setValue('image', res.secure_url);
        setUploading(false);
      })
      .catch((err) => {
        console.error(err);
        setUploading(false);
      });
  };
  const handleSubmitInForUser = async (data) => {
    const id = idUser;
    try {
      const url = `https://api.dashop.site/users/update/${id}`;
      const opt = {
        method: 'put',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
      };
      const res = await fetch(url, opt);
      const reponseData = await res.json();
      alert('Đã Cập Nhật Thông Tin Thành Công', reponseData);
    } catch (error) {
      console.error('Lỗi khi gửi form: ', error);
    }
  };
  useEffect(() => {
    const getInforUser = async () => {
      const id = idUser;
      try {
        const url = `https://api.dashop.site/users/info/${id}`;
        const res = await fetch(url);
        const data = await res.json();
        dispatch(capnhatUserInfo({ userInfo: data }));
        localStorage.setItem(
          'result',
          JSON.stringify({
            expiresIn: result.expiresIn,
            idToken: result.idToken,
            userInfo: data,
          })
        );
      } catch (error) {
        console.error('Lỗi khi lấy thông tin người dùng: ', error);
      }
    };

    if (isSubmitSuccessful) {
      getInforUser();
    }
  }, [isSubmitSuccessful, idUser, dispatch, result.expiresIn, result.idToken]);

  const handleLogout = () => {
    // Xóa thông tin người dùng khỏi localStorage
    localStorage.removeItem('result');
    // Cập nhật Redux store
    dispatch(thoat());
    navigate('/');
  };

  return (
    <section className="infoUser">
      <Navbar />
      <div className="container">
        <h1 className="infoUer-heading">Thông tin cá nhân</h1>
        <div className="infoUser-wrapper">
          <div className="infoUser-image">
            <img
              src={
                user.avatar
                  ? user?.avatar
                  : 'https://www.shutterstock.com/image-vector/blank-avatar-photo-icon-design-600nw-1682415103.jpg'
              }
              alt="avatar-user"
            />
            <p className="infoUser-name">{user?.name}</p>
            <p className="infoUser-info">{user?.phone}</p>
            <p className="infoUser-info">{user?.email}</p>
          </div>
          <div className="infoUser-content">
            <form
              onSubmit={handleSubmit(handleSubmitInForUser)}
              noValidate>
              <h3 className="infoUser-title">Thay đổi thông tin cá nhân</h3>
              <div className="infoUser-list">
                <div className="infoUser-item">
                  <label className="infoUser-text">Tên người dùng</label>
                  <input
                    placeholder="Tên"
                    type="text"
                    {...register('name')}
                  />
                  <p className="err">{errors.name?.message}</p>
                </div>
                <div className="infoUser-item">
                  <label className="infoUser-text">Số điện thoại</label>
                  <input
                    placeholder="Số điện thoại"
                    type="text"
                    {...register('phone')}
                  />
                  <p className="err">{errors.phone?.message}</p>
                </div>
                <div className="infoUser-item">
                  <label className="infoUser-text">Email</label>
                  <input
                    placeholder="Email"
                    type="email"
                    {...register('email')}
                  />
                  <p className="err">{errors.email?.message}</p>
                </div>
                <div className="infoUser-item">
                  <label className="infoUser-text">Địa chỉ</label>
                  <input
                    placeholder="Địa chỉ, Xã/Phường, Quận/Huyện, Tỉnh/Thành Phố"
                    type="text"
                    {...register('address')}
                  />
                  <p className="err">{errors.address?.message}</p>
                </div>
                <div className="infoUser-item">
                  <label className="infoUser-text">Ảnh đại diện</label>
                  <input
                    type="file"
                    id="avatar"
                    onChange={(e) => handleFileUpload(e)}
                  />
                  <p className="err">{errors.image?.message}</p>
                </div>
              </div>
              <button
                className="infoUser-button"
                type="submit"
                disabled={uploading}>
                Thay đổi
              </button>
            </form>
            <DevTool control={control} />
          </div>
          <div className="infoUser-packlink">
            <Link to="/reset-pass">
              <p className="infoUser-link">Đổi mật khẩu</p>
            </Link>
            <Link
              to="/"
              onClick={handleLogout}>
              <p className="infoUser-link">Đăng xuất</p>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InfoUser;
